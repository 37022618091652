.seqrops-login-wrapper {
  display: flex;
  justify-content: center;
  background-color: white;
  // margin-top: 25vh;
}

.seqrops-login-logo-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 384px;
    margin-bottom: 20px;
  }
  margin-top: 25vh;
}

.seqrops-login-form-head-section {
  p {
    font-size: 31px;
    text-align: left;
    font-weight: 500;
  }
}
.seqrops-login-form-section {
  width: 490px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid #eee;
  padding: 50px 10px 50px 10px;
  margin-bottom: 5px;
  margin: 0 auto;
  border-radius: 13px;

  label {
    display: flex;
    justify-content: start;
    font-size: 15px;
  }

  .seqrops-login-buttons-section {
    button {
      padding: 10px;
      border-radius: 5px;
      width: 5vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      background-color: #082072;
      color: white;
      margin-left: 10px;
    }
    button:hover {
      background-color: #082072;
    }
    .register-btn {
      background-color: #082072;
    }
  }
}
.seqrops-user-services-section {
  a {
    display: flex;
    justify-content: end;
    text-decoration: none;
    color: #1363df;
    font-size: 14px;
  }
}

.powedby {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: 10px;
  p {
    color: #363f5e;
  }
}
.seqropsLogSideBar {
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
}
