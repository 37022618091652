.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.seqrops-dashboard-console {
  padding: 80px;
  background-color: whitesmoke;
  height: 95vh;
}
.seqrops-dashboard-tile-wrapper {
  cursor: pointer;
  background-color: white;
  margin: 15px;
  border-radius: 12px;
  text-align: left;
  border: 1px solid #eee;
  width: 30%;
  padding: 0 20px;
  box-shadow: rgba(149, 157, 145, 0.2) 0px 8px 24px;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  a {
    padding: 10px;
    text-decoration: none;
    color: black;
  }
  img {
    width: 3vw;
  }
}

.tile-wrapper-main {
  display: flex;
  justify-content: space-evenly;
}

.seqrops-dashboard-tile-wrapper:hover {
  background-color: rgb(202, 224, 240);
}

.seqrops-tile-inner-section {
  display: flex;
  align-items: center;
  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    font-weight: 500;
    color: #363f5e;
  }
}

.seqrops-dashboard-tile-wrapper {
  transition: transform 0.5s;
}

.zoom-effect {
  transform: scale(1.1);
}
