.seqrops-login-console {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message-section {
  color: red;
  font-size: 13px;
  margin-bottom: 0;
}

.userNameLabel {
  color: #6a7086;
}
.passwordLabel {
  color: #6a7086;
}
.username {
  .form-control {
    border: 1px solid #b6c1ea !important;
  }
}
.password {
  .form-control {
    border: 1px solid #b6c1ea !important;
  }
}
.seqrops-login-form-head-section{
    p{
        color:#363f5e ;
    }
}
